<template>
  <v-card class="pa-0 pb-10" tile>
    <v-card-title>
      <h4 class="primary--text">
        {{ $t("profile.settings") }}
      </h4>
    </v-card-title>
    <div class="background px-6">
      <v-tabs v-model="tab" :height="60" background-color="background" show-arrows hide-slider center-active>
        <v-tab v-if="!$store.getters['isDriver']"> {{ $t("profile.info") }} </v-tab>
        <v-tab> {{ $t("profile.password") }} </v-tab>
        <v-tab v-if="!$store.getters['isDriver']"> {{ $t("profile.notifications") }} </v-tab>
        <v-tab> {{ $t("profile.consent") }} </v-tab>
      </v-tabs>
    </div>
    <v-tabs-items v-model="tab">
      <v-tab-item v-if="!$store.getters['isDriver']">
        <Informations />
      </v-tab-item>
      <v-tab-item>
        <Devices />
      </v-tab-item>
      <v-tab-item v-if="!$store.getters['isDriver']">
        <Notifications />
      </v-tab-item>
      <v-tab-item>
        <Consent/>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import Informations from './tabs/Informations.vue'
import Devices from './tabs/Devices.vue'
import Notifications from './tabs/Notifications.vue'
import Consent from '@/views/Settings/Profile/tabs/Consent'

export default {
  name: 'User',
  components: { Informations, Devices, Notifications, Consent },
  data () {
    return {
      tab: null,
      loading: false
    }
  }
}
</script>
<style scoped>
.v-tab::before {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
}

/* .v-tab--active, */
.v-tab:hover {
  /* font-weight: bold; */
  color: var(--v-primary-base) !important;
  text-shadow: 0 0 .7px var(--v-primary-base), 0 0 .7px var(--v-primary-base);
}

.v-tab--active:hover,
.v-tab--active {
  font-weight: bold;
  color: var(--v-primary-base) !important;
  text-shadow: none;
}

.v-tab{
  border-left: solid 1px var(--v-separator-base);
  margin: 10px 0;
}

.v-tab:last-child {
  border-right: solid 1px var(--v-separator-base);
  margin: 10px 0;
}
</style>
