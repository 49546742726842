<template>
<div class="pa-6">
  <v-row v-if="user">
    <v-col cols="12" md="6">
      <v-card class="h-100" outlined flat>
        <v-card-text class="pa-4">
          <v-row>
            <v-col cols="4">
              <div v-if="user.avatar && user.avatar != ''">
                <v-avatar rounded class="avatar-responsive">
                  <img :src="getAvatar(user.avatar)" :alt="user.name" />
                </v-avatar>
                <v-btn color="primary" small outlined :disabled="loadingAvatar" :loading="loadingAvatar" @click="removeAvatar" style="width:100px">
                  {{ $t("profile.informations.delete") }}
                </v-btn>
              </div>
              <div v-else>
                <div class="avatar rounded mb-4"></div>
                <v-btn color="primary" outlined :disabled="loadingAvatar" :loading="loadingAvatar" @click="$refs.uploader.click()" small style="width:100px">
                  {{ $t("profile.informations.add") }}
                </v-btn>
                <input ref="uploader" class="d-none" type="file" accept="image/*" @change="addAvatar" />
              </div>
            </v-col>
            <v-col cols="8">
              <label> {{ $t("profile.informations.id") }} </label>
              <v-text-field
                :value="user.displayId"
                outlined
                dense
                disabled
              ></v-text-field>
              <label> {{ $t("profile.informations.lang") }} </label>
              <v-select
                v-model="user.language"
                :items="languages"
                item-text="label"
                item-value="value"
                dense
                outlined
                @change="changeLang"
              ></v-select>
              <template
                v-if="$store.getters.isMarketUser || $store.getters.isDriver"
              >
                <label> {{ $t("profile.informations.currency") }} </label>
                <v-select
                  v-model="user.defaultCurrency"
                  :items="currencies"
                  item-text="description"
                  item-value="id"
                  dense
                  outlined
                  @change="changeCurrency"
                ></v-select>
              </template>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="6">
      <v-card class="h-100" outlined flat>
        <v-card-text class="pa-4">
          <template v-if="!user.profileChanges">
            <v-form ref="updateProfileForm" v-model="valid" :disabled="submitLoading">
              <v-row>
                <v-col cols="6">
                  <h3 class="primary--text mb-2">
                    {{ $t("profile.informations.contact") }}
                  </h3>
                  <label> {{ $t("profile.informations.name") }} </label>
                  <v-text-field
                    v-model="user.name"
                    outlined
                    dense
                    single-line
                    :rules="required"
                  ></v-text-field>
                  <label> {{ $t("profile.informations.surname") }} </label>
                  <v-text-field
                    v-model="user.surname"
                    outlined
                    dense
                    :rules="required"
                  ></v-text-field
                  ><label> {{ $t("profile.informations.phone") }} </label>
                  <v-text-field
                    v-model="user.phone"
                    outlined
                    dense
                    :rules="[...required, maxCharInPhoneNumber]"
                  ></v-text-field>
                  <label> {{ $t("profile.informations.email") }} </label>
                  <v-text-field
                    :value="user.email"
                    outlined
                    dense
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <h3 class="primary--text mb-2">
                    {{ $t("profile.informations.address") }}
                  </h3>
                  <label> {{ $t("profile.informations.street") }} </label>
                  <v-text-field
                    v-model="user.street"
                    outlined
                    dense
                    :disabled="isMarketUser"
                    single-line
                    :rules="required"
                  ></v-text-field
                  ><label> {{ $t("profile.informations.zipCode") }} </label>
                  <v-text-field
                    v-model="user.zipCode"
                    outlined
                    dense
                    :disabled="isMarketUser"
                    single-line
                    :rules="[...required, maxCharInZipCode]"
                  ></v-text-field>
                  <label> {{ $t("profile.informations.city") }} </label>
                  <v-text-field
                    v-model="user.city"
                    outlined
                    dense
                    :disabled="isMarketUser"
                    single-line
                    :rules="required"
                  ></v-text-field>
                </v-col>
              </v-row>
              <div class="d-flex">
                <v-btn outlined class="ml-auto" :disabled="submitLoading || disabledUserMarketSubmitButton" :loading="submitLoading" @click="profileSubmit">
                  {{ $t("profile.informations.update") }}
                </v-btn>
              </div>
            </v-form>
          </template>
          <template v-else>
              <v-chip label color="primary" class="mb-2" small>
                {{ $t("profile.informations.updated") }}
              </v-chip>
              <v-row>
                <v-col cols="6">
                  <h3 class="primary--text mb-2">
                    {{ $t("profile.informations.contact") }}
                  </h3>
                  <label>Imię</label>
                  <v-text-field
                    :value="user.profileChanges.name"
                    outlined
                    dense
                    disabled
                  ></v-text-field>
                  <label> {{ $t("profile.informations.surname") }} </label>
                  <v-text-field
                    :value="user.profileChanges.surname"
                    outlined
                    dense
                    disabled
                  ></v-text-field
                  ><label> {{ $t("profile.informations.phone") }} </label>
                  <v-text-field
                    :value="user.profileChanges.phone"
                    outlined
                    dense
                    disabled
                  ></v-text-field>
                  <label> {{ $t("profile.informations.email") }} </label>
                  <v-text-field
                    :value="user.email"
                    outlined
                    dense
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <h3 class="primary--text mb-2">
                    {{ $t("profile.informations.address") }}
                  </h3>
                  <label> {{ $t("profile.informations.street") }} </label>
                  <v-text-field
                    :value="user.street"
                    outlined
                    dense
                    disabled
                  ></v-text-field
                  ><label> {{ $t("profile.informations.zipCode") }} </label>
                  <v-text-field
                    :value="user.zipCode"
                    outlined
                    dense
                    disabled
                  ></v-text-field>
                  <label> {{ $t("profile.informations.city") }} </label>
                  <v-text-field
                    :value="user.city"
                    outlined
                    dense
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
          </template>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</div>
</template>

<script>
import Service from '@/services'
import { fileUrl, changeLocale, maxCharInPhoneNumber, maxCharInZipCode } from '@/utils/helpers'
import { mapGetters } from 'vuex'

export default {
  name: 'Informations',
  data () {
    return {
      user: null,
      userOrigin: null,
      lang: [],
      languages: [],
      valid: true,
      files: [],
      submitLoading: false,
      loadingAvatar: false,
      required: [(v) => (!!v && !!v.trim()) || this.$t('validation.fieldRequired')],
      maxCharInPhoneNumber,
      maxCharInZipCode
    }
  },
  computed: {
    ...mapGetters({
      isMarketUser: 'isMarketUser',
      allCurrencies: 'allCurrencies',
      ads: 'isD'
    }),
    currencies () {
      return this.allCurrencies.filter(c => c.isActive)
    },
    disabledUserMarketSubmitButton () {
      return JSON.stringify(this.user) === JSON.stringify(this.userOrigin)
    }
  },
  created () {
    this.getUser()
    this.fetchLanguages()
  },
  methods: {
    async getUser () {
      this.loading = true
      try {
        if (this.isMarketUser) {
          const { data } = await Service.get('/User/GetCurrentCompanyUser')
          this.user = data
          this.userOrigin = JSON.parse(JSON.stringify(this.user))
        } else {
          const { data } = await Service.get('/User/GetCurrentUser')
          this.user = data
        }
      } catch (e) {}
      this.loading = false
    },
    async fetchLanguages () {
      const { data } = await Service.get('Frontend/GetAvailableLanguages')
      this.lang = data
      this.languages = data.map(language => {
        return {
          value: language,
          label: this.$t(`languages.${language}`)
        }
      })
    },
    initials (name, surname) {
      return name.slice(0, 1) + surname.slice(0, 1)
    },
    getAvatar (url) {
      return fileUrl(url)
    },
    async changeCurrency () {
      try {
        await Service.put('/Currency/ChangeMyDefaultCurrency', {
          defaultCurrency: this.user.defaultCurrency
        })
        await this.$store.dispatch('fetchUserData')
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('profile.informations.langCurrency')
        )
      } catch (e) {}
    },
    async changeLang () {
      try {
        await Service.put('/User/ChangeLanguage', {
          targetLanguage: this.user.language
        })
        await changeLocale(this.user.language, this.lang)

        await this.fetchLanguages()
        await this.$store.dispatch('enums')
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('profile.informations.langChange')
        )
      } catch (e) {}
    },
    async addAvatar (e) {
      this.loadingAvatar = true

      const files = Array.from(e.target.files)
      const file = new FormData()
      file.append('file', files[0], files[0].name)

      try {
        await Service.post('/User/AddUserAvatar', file)
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('profile.informations.avatarChange')
        )
        await this.getUser()
      } catch (error) {}

      this.loadingAvatar = false
    },
    async removeAvatar () {
      this.loadingAvatar = true
      try {
        await Service.delete('/User/DeleteUserAvatar')
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('profile.informations.avatarRemove')
        )

        await this.getUser()
      } catch (error) {}
      this.loadingAvatar = false
    },
    async profileSubmit () {
      this.$refs.updateProfileForm.validate()
      if (!this.valid) {
        return
      }
      this.submitLoading = true
      try {
        if (this.isMarketUser) {
          await Service.post('/User/UpdateCompanyUserProfile', {
            name: this.user.name,
            surname: this.user.surname,
            phone: this.user.phone
          })
        } else {
          await Service.post('/User/UpdateUserProfile', {
            name: this.user.name,
            surname: this.user.surname,
            phone: this.user.phone,
            city: this.user.city,
            street: this.user.street,
            zipCode: this.user.zipCode,
            country: this.user.country
          })
        }

        this.$refs.updateProfileForm.resetValidation()

        await this.getUser()
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('profile.informations.updateChange')
        )
      } catch (e) {}
      this.submitLoading = false
    }
  }
}
</script>
<style scoped>
.avatar {
  width: 100px;
  height: 100px;
  border: solid thin  var(--v-primary-base);
  background-color: var(--v-background-base);
}
</style>
