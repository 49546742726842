<template>
<div class="pa-6">
    <v-row>
      <v-col cols="12" md="6">
        <v-card class="h-100" outlined flat>
          <v-card-text class="pa-4">
            <h3 class="primary--text mb-4">{{ $t('profile.devices.pass') }}</h3>
            <v-form ref="loginForm" v-model="valid">
              <label>{{ $t("profile.devices.password") }}</label>
              <v-text-field
                v-model="form.oldPassword"
                :append-icon="form.oldPasswordShow ? 'mdi-eye' : 'mdi-eye-off'"
                :type="form.oldPasswordShow ? 'text' : 'password'"
                outlined
                dense
                single-line
                validate-on-blur
                :rules="passwordRules"
                @click:append="form.oldPasswordShow = !form.oldPasswordShow"
              ></v-text-field>
              <label>{{ $t("profile.devices.newPassword") }}</label>
              <v-text-field
                v-model="form.password"
                :append-icon="form.passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                :type="form.passwordShow ? 'text' : 'password'"
                outlined
                dense
                single-line
                :rules="newPasswordRules"
                @click:append="form.passwordShow = !form.passwordShow"
              ></v-text-field>
              <CheckPasswordStrength
                v-if="form.password.length > 3"
                :value="form.password"
              />
              <label>{{ $t("profile.devices.repeatPassword") }}</label>
              <v-text-field
                v-model="form.confirmPassword"
                :append-icon="form.confirmPasswordShow ? 'mdi-eye' : 'mdi-eye-off'"
                :type="form.confirmPasswordShow ? 'text' : 'password'"
                outlined
                dense
                single-line
                :rules="confrimPasswordRules"
                @click:append="form.confirmPasswordShow = !form.confirmPasswordShow"
              />
              <div class="d-flex mt-8">
                <v-btn outlined color="primary" :disabled="submitLoading" @click="generatePassword">
                  {{ $t("resetPassword.generatePassword") }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" outlined class="mr-2" @click="clear" :disabled="submitLoading">
                  {{ $t("profile.devices.clear") }}
                </v-btn>
                <v-btn color="primary" :disabled="submitLoading" :loading="submitLoading" @click="submitHandle" depressed>
                  {{ $t("resetPassword.submit") }}
                </v-btn>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card class="h-100" outlined flat>
          <v-card-text class="pa-4">
            <h3 class="primary--text mb-4">{{ $t('profile.devices.devices') }}</h3>
            <div v-if="devices && devices.length > 0">
              <div
                v-for="device in devices"
                :key="device.id"
                class="d-flex rounded grey pointer lighten-4 py-2 px-4 mb-2"
                @click="edit(device)"
              >
                <span>{{ device.name }}</span>
                <v-icon class="ml-auto" small @click.stop="remove(device)">
                  mdi-window-close
                </v-icon>
              </div>
            </div>
            <div v-else>
              <p>{{ $t('profile.devices.noDevices') }}</p>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <Confirm ref="confirm" />
    <update-device
      v-if="updateDialog"
      :dialog="updateDialog"
      :device="selectedDevice"
      :loading="loadingDialog"
      @cancel="updateDialogCancel"
      @submit="updateDialogSubmit"
    ></update-device>
  </div>
</template>

<script>
import Service from '@/services'
import { generateString } from '@/utils/helpers'
import CheckPasswordStrength from '@/components/CheckPasswordStrength'
import UpdateDevice from '../dialogs/UpdateDevice.vue'
import Confirm from '@/components/dialogs/Confirm.vue'

export default {
  name: 'Devices',
  components: {
    CheckPasswordStrength,
    UpdateDevice,
    Confirm
  },
  data () {
    return {
      devices: null,
      notifications: null,
      form: {
        oldPassword: '',
        oldPasswordShow: false,
        password: '',
        passwordShow: false,
        confrimPassword: '',
        confirmPasswordShow: false
      },
      selectedDevice: null,
      submitLoading: false,
      loadingDialog: false,
      updateDialog: false,
      valid: true,
      passwordRules: [
        (v) => !!v || this.$t('validation.passwordRequired')],
      newPasswordRules: [
        (v) => !!v || this.$t('validation.passwordRequired'),
        (v) => v.length >= 8 || this.$t('validation.passwordInvalidLength')],
      confrimPasswordRules: [
        (v) => !!v || this.$t('validation.confirmPasswordRequired'),
        (v) =>
          v === this.form.password || this.$t('validation.passwordsMustMatch')
      ]
    }
  },
  created () {
    this.getDevices()
  },
  methods: {
    async getDevices () {
      this.loading = true

      try {
        const { data } = await Service.get('/Device/GetMyDevices')
        this.devices = data
      } catch (e) {}

      this.loading = false
    },
    generatePassword () {
      const newPassword = `${generateString()}${generateString()}`
      this.form.password = newPassword
      this.form.confirmPassword = newPassword
    },
    async submitHandle () {
      this.$refs.loginForm.validate()
      if (!this.valid) {
        return
      }
      this.submitLoading = true
      try {
        await Service.put('/User/ChangePassword', {
          password: this.form.password,
          oldPassword: this.form.oldPassword
        })
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('profile.devices.passChange')
        )
        this.clear()
      } catch (e) {}
      this.submitLoading = false
    },
    clear () {
      this.$refs.loginForm.resetValidation()
      this.form = Object.assign(
        {},
        {
          oldPassword: '',
          oldPasswordShow: false,
          password: '',
          passwordShow: false,
          confrimPassword: '',
          confirmPasswordShow: false
        }
      )
    },
    edit (device) {
      this.selectedDevice = device
      this.updateDialog = true
    },
    async remove (device) {
      this.loadingDialog = true
      if (await this.$refs.confirm.open(this.$t('profile.devices.removeChange'), this.$t('profile.devices.title'))) {
        try {
          await Service.delete('/Device/DeleteDevice', {
            deviceId: device.deviceId
          })
          this.getDevices()
          await this.$store.dispatch(
            'setSuccessNotification',
            this.$t('profile.devices.remove')
          )
        } catch (e) {}
      }
      this.loadingDialog = false
    },
    async updateDialogSubmit (name) {
      this.loadingDialog = true
      try {
        await Service.put('/Device/UpdateDevice', {
          deviceId: this.selectedDevice.deviceId,
          name: name
        })
        this.updateDialog = false
        this.getDevices()
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('profile.devices.updateChange')
        )
      } catch (e) {}
      this.loadingDialog = false
    },
    updateDialogCancel () {
      this.updateDialog = false
      this.selectedDevice = null
    }
  }
}
</script>

<style>
</style>
