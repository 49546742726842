<template>
<div class="pa-6">
    <v-row v-if="notifications">
      <v-col lg="6" sm="12">
        <v-card class="h-100" outlined flat>
          <v-card-text class="pa-4">
            <h3 class="primary--text">{{ $t("profile.notify.code") }}</h3>
            <!-- TODO: update userCommonData-->
            <v-checkbox
              hide-details
              class="mt-2"
              :disabled="!notifications.allowSms && notifications.allowEmail"
              :label="`e-mail: ${userCommonData.email || ''}`"
              v-model="notifications.allowEmail"
              @change="changeNotify"
            ></v-checkbox>
            <!-- TODO: update userCommonData-->
            <v-checkbox
              hide-details
              class="mt-2"
              :disabled="!notifications.allowEmail && notifications.allowSms"
              :label="`SMS: ${userCommonData.phone || ''}`"
              v-model="notifications.allowSms"
              @change="changeNotify"
            ></v-checkbox>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col lg="6" sm="12">
        <v-card class="h-100" outlined flat>
          <v-card-text class="pa-4">
            <h3 class="primary--text">{{ $t("profile.notify.tsl") }}</h3>
            <v-checkbox
              hide-details
              class="mt-2"
              :label="$t('profile.notify.notify')"
              v-model="notifications.showSystemNotifications"
              :disabled="notifications.showWebPushNotifications"
              @change="changeNotify"
            ></v-checkbox>
            <v-checkbox
              hide-details
              class="mt-2"
              :label="$t('profile.notify.systemNotify')"
              v-model="notifications.showWebPushNotifications"
              :disabled="!notifications.showSystemNotifications"
              @change="changeNotify"
            ></v-checkbox>
             <v-checkbox
              hide-details
              class="mt-2"
              :label="$t('profile.notify.sendEmailNotify')"
              v-model="notifications.sendEmailNotifications"
              @change="changeNotify"
            ></v-checkbox>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="notificationSounds">
      <v-col cols="12" md="6">
        <v-card outlined flat>
          <v-card-text class="pa-4">
            <h3 class="primary--text">{{ $t("profile.notify.sounds") }}</h3>
            <div v-for="notificationSound in notificationSounds" :key="notificationSound.soundtype" class="d-flex align-center mb-2">
              <v-btn class="mr-4" text icon color="primary" @click="playSoundTest(notificationSound.soundType)">
                <v-icon>$sound</v-icon>
              </v-btn>
              <span>
                {{ notificationSound.soundType | getDescription(notificationSoundTypes)}}
              </span>
              <div class="ml-auto w-50">
                <label>{{ $t('profile.notify.assignToNotification') }}</label>
                <v-select
                  v-model="notificationSound.notificationTypes"
                  :items="systemNotificationTypes(notificationSound.soundType)"
                  item-text="description"
                  item-value="notificationType"
                  multiple
                  item-disabled="disabled"
                  outlined
                  dense
                  hide-details
                  @blur="updateNotificationSounds"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip dark color="primary" v-if="index === 0" class="mt-1" small>
                      <span>{{ item.description }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text text-caption mt-1">
                      (+{{ notificationSound.notificationTypes.length -1 }} {{ $t('common.others')}})
                    </span>
                  </template>
                </v-select>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Service from '@/services'
import { mapActions } from 'vuex'

export default {
  name: 'Notifications',
  data () {
    return {
      notifications: null,
      notificationSounds: null,
      availableNotificationTypes: null
    }
  },
  async created () {
    await this.getNotifications()
    await this.getAvailableNotificationTypes()
    await this.getNotificationSounds()
  },
  computed: {
    userCommonData () {
      return this.$store.getters.userCommonData
    },
    notificationSoundTypes () {
      return this.$store.getters.enums.systemNotificationSoundTypes
    }
  },
  methods: {
    ...mapActions({
      playSoundTest: 'notification/playSoundTest'
    }),
    async getNotifications () {
      try {
        const { data } = await Service.get('/User/GetNotificationsSettings')
        this.notifications = data
      } catch (e) {}
    },
    async getNotificationSounds () {
      try {
        const { data } = await Service.get('/User/GetNotificationSounds')
        this.notificationSounds = data
      } catch (e) {}
    },
    async changeNotify () {
      try {
        await Service.put('/User/UpdateNotificationSettings', this.notifications)
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('profile.notify.nofityChange')
        )
      } catch (e) {}
    },
    async updateNotificationSounds () {
      try {
        await Service.put('/User/UpdateNotificationSounds', {
          notificationSounds: this.notificationSounds
        })

        await this.getAvailableNotificationTypes()

        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('profile.notify.nofityChange')
        )
      } catch (e) {}
    },
    async getAvailableNotificationTypes () {
      try {
        const { data } = await Service.get('/User/GetAvailableNotificationTypes')
        this.availableNotificationTypes = data
      } catch (e) {}
    },
    systemNotificationTypes (soundType) {
      return this.availableNotificationTypes.filter((availableNotification) => {
        if (availableNotification.soundType === soundType) {
          return availableNotification
        }
      })
    }
  }
}
</script>
